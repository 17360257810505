*::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000;
}
*::-moz-selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000;
}

*::-webkit-scrollbar {
  width: 3px;
  display: none;
}
*::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

*::-webkit-scrollbar-thumb {
  background: #000;
  border: none;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  background-color: #000;
  color: #fff;
}


button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
}


// @include mobile {
  
// }