@import '../styles/global-styles.scss';

.vimeo {
  &__container {
    padding: 0px 30px 100px 30px;
  }

  &__title{
      text-align: center;
      margin: 100px 0 30px 0;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.27198%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
  }

  &__password-wrapper{
      width: 100%;
      height: 100%;
      border: 1px solid #FFF;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background-color: black;
      transition: opacity 0.4s ease-in-out;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.disabled{
          opacity: 0;
          pointer-events: none;
      }
      h3{
          margin-bottom: 10px;
      }
      input[type=password],
      input[type=submit]{
          background-color: transparent;
          border: 1px solid #FFF;
          border-radius: 3px;
          outline: none;
          box-shadow: none;
          color: #FFF;
          font-size: 16px;
          line-height: 16px;
          padding: 10px 5px;
          font-family: 'Antwerp_Light', serif;
      }
      input[type=submit]{
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          @include hover{
              background-color: #FFF;
              color: #000;
          }
      }
  }


}

@include mobile{

    .vimeo {
        &__container {
          padding: 50px 20px;
          
        }
        &__title{
            margin: 0 0 30px 0;
        }
    }
}