@import '../styles/global-styles.scss';


.preloader {
  &__container {
    width: 40vw;
    height: 100vh;
    padding: 0 30vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__logo {
    display: block;
    width: 100%;
    height: auto;
    // opacity: 0.5;
  }

  &__footer-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  &__tag-line{
      margin-bottom: 10px;
  }
  &__arrow{
    font-size: 40px;
    line-height: 40px;
      animation: pulse 2s linear infinite ;
  }
}


@include mobile {
    .preloader {
        &__container {
          width: 80%;
          height: 100vh;
          padding: 0;
          margin: 0 10%;
        }
        &__logo {
          display: block;
          width: 100%;
          height: auto;
        }
      
        &__footer-wrapper {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          margin: 30px auto;
        }
        &__tag-line{
            margin-bottom: 10px;
        }
        &__arrow{
            animation: pulse 2s linear infinite ;
        }
      }
}