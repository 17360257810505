@import '../styles/global-styles.scss';

.about{

    &__container{
        margin: 200px auto;
        // text-align: center;
        text-align: left;
        max-width: 600px;
        p{
            margin-bottom: 20px;
        }
        h1{
            margin-bottom: 50px;
        }

        h2{
            text-align: left;
            margin: 0 10vw 30px 10vw;
        }
        em{
            font-size: inherit;
            line-height: inherit;
            font-style: italic;
          }
    }
}

@include mobile{

    .about{

        &__container{
            margin: 100px 40px;
            h1{
                margin-bottom: 50px;
            }
    
            h2{
                text-align: left;
                margin: 0 0 30px 0;
            }
            em{
                font-size: inherit;
                line-height: inherit;
                font-style: italic;
              }
        }
    }
}