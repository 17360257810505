@font-face {
  font-family: "GT-Zirkon-Regular";
  src: url("../assets/GT-Zirkon-Regular.eot");
  src: url("../assets/GT-Zirkon-Regular.woff") format("woff"),
    url("../assets/GT-Zirkon-Regular.ttf") format("truetype"),
    url("../assets/GT-Zirkon-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GT-Flexa-Bold";
  src: url("../assets/GT-Flexa-Bold.eot");
  src: url("../assets/GT-Flexa-Bold.woff") format("woff"),
    url("../assets/GT-Flexa-Bold.ttf") format("truetype"),
    url("../assets/GT-Flexa-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Antwerp_Light";
  src: url("../assets/Antwerp_Light.eot");
  src: url("../assets/Antwerp_Light.woff") format("woff"),
    url("../assets/Antwerp_Light.ttf") format("truetype"),
    url("../assets/Antwerp_Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  font-family: 'Antwerp_Light', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 18px;
}

em{
  font-size: 20px;
  line-height: 22px;
  font-style: italic;
}

h1, h2 {
  font-family: "GT-Flexa-Bold", sans-serif;
  text-transform: uppercase;
}
h1{
  font-size: 3vw;
  line-height: 3vw;
}
h2 {
  font-size: 20px;
  line-height: 22px;
}


.font-red{
  color: #F00;
}

a{
  text-decoration: none;
  color: inherit;
  @include hover{
    color: inherit;
    text-decoration: underline;
  }
}

@include mobile{
  h1{
    font-size: 20px;
    line-height: 20px;
  }
  h2 {
    font-size: 16px;
    line-height: 18px;
  }
}
