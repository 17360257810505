@import '../styles/global-styles.scss';

.partners{

    &__container{
        margin: 0 20% 200px 20%;
        width: 60%;
        text-align: center;
    }

    &__wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
    }

    &__partner-wrapper{
        height: auto;
        margin: 0 20px;
    }

    &__logo{
        display: block;
        height: 30px;
        width: auto;
        max-width: 100%;
        object-fit: contain;
        margin: 10px auto;
        &.portrait{
            height: 60px;
            margin: 0 auto;
        }
    }
}

@include mobile {

    .partners{

        &__container{
            margin: 0 0 100px 0;
            width: 100%;
        }
    
        &__wrapper{
            display: block;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
        }
    
        &__partner-wrapper{
            height: auto;
            margin: 0 20px;
        }
    
        &__logo{
            display: block;
            height: 40px;
            width: auto;
            max-width: calc(100% - 40px);
            object-fit: contain;
            margin: 0 auto 30px auto;
            &.portrait{
                height: 60px;
                margin-bottom: 30px;
            }
        }
    }
}