@import '../styles/global-styles.scss';

.team {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  &__wrapper{
    margin: 100px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  &__item-wrapper {
    width: 15%;
    margin: 0 50px 40px 50px;
  }

  &__pic-wrapper {
    width: 100%;
    padding-top: 100%;
    background-color: #fff;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
  }

  &__pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@include mobile{
  .team {
    &__container {
      display: block;
      text-align: center;
    }
  
    &__wrapper{
      margin: 100px 0;
      display: block;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: nowrap;
    }
  
    &__item-wrapper {
      width: 60%;
      margin: 0 20% 30px 20%;
    }
  
    &__pic-wrapper {
      width: 100%;
      padding-top: 100%;
      background-color: #fff;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 20px;
    }
  
    &__pic {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}