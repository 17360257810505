@import '../styles/global-styles.scss';

.contact{

    &__container{
        margin: 0 30px;
        padding-bottom: 200px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        position: relative;
    }

    &__half{
        width: 50%;
        &.left{
            width: calc(50% - 60px);
            padding: 0 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    }
    &__copyright{
        margin: 30px auto;
        width: 100%;
        position: absolute;
        bottom: 0;
        text-align: center;
    }
}

@include mobile {

    .contact{

        &__container{
            margin: 0 20px;
            padding: 100px 0 200px 0;
            display: block;
        }
    
        &__half{
            width: 100%;
            text-align: center;
            &.left{
                width: 100%;
                padding: 0 ;
                margin-bottom: 30px;
                display: block;
            }
        }
        &__copyright{
            margin: 30px auto;
            width: 100%;
            position: absolute;
            bottom: 0;
            text-align: center;
        }
    }

}